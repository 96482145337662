<template>
    <div @mouseenter="store.getComponentCursor($event, null)" class="media-single ">
        <span class="date fira">{{ item.date }}</span>
        <h2 class="header-title fira">{{ item.title }}.</h2>
        <div class="tag-container">
            <div v-for="tag in item.tags" class="tag">
                <span class="fira title"> {{ tag.title }}</span>
            </div>
        </div>
        <div @click="openVideo(item.video1url)"
             class="image-inner">
            <img v-if="item.video1url" src="../../../assets/icons/play.png" alt="" class="icon">
            <picture class="project-item-image">
                <source
                    media="(max-width:767px)"
                    :srcset="item.mobile1"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="item.tablet1"
                />
                <img class="image"
                     :src="item.desktop1"
                />
            </picture>
        </div>
        <div class="content" v-html="item.content"></div>
        <div @click="openVideo(item.video2url)"
             v-if="item.video2url"
             class="image-inner">
            <img src="../../../assets/icons/play.png"
                 alt="" class="icon">
            <picture class="project-item-image">
                <source
                    media="(max-width:767px)"
                    :srcset="item.mobile2"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="item.tablet2"
                />
                <img class="image"
                     :src="item.desktop2"
                />
            </picture>
        </div>
        <div class="media-footer">
            <h2 class="title fira">{{ $fn.tr('Thanks for reading') }}</h2>
            <div class="down">
                <div class="left box">
                    <h2 class="title fira">{{ $fn.tr('Share') }}:</h2>
                    <a
                        :href="`${item.url}${getFullUrl}`"
                        onclick="window.open(this.href,'targetWindow',
                                   `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=800`);
 return false;"
                        v-for="(item, index) in socialMedia"
                        :key="index"
                    >
                        <div class="icon" v-html="item.svg"></div>
                    </a>
                </div>
                <div @click="changeUrl" class="right box">
                    <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                              fill="#940128"></path>
                    </svg>
                    <span class="title fira">{{ $fn.tr('Back') }}</span>
                </div>
            </div>
        </div>
        <teleport to="body">
            <div class="project-overview-main__video-modal media abs" v-if="isVideoOpen && activeVideo">
                <div class="lh-0 flex al-center j-center abs" v-html="burgerCloseIcon" v-if="windowWidth <= 1199"
                     @click="handleVideoClose"></div>
                <video preload="auto" controls data-scale-type="1.5" data-cursor-type="zoom-cancel"
                       @click.prevent="handleVideoClose" class="pointer">
                    <source :src="activeVideo" type="video/mp4">
                </video>
            </div>
        </teleport>
    </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import moment from 'moment'
import {useRouter} from "vue-router";
import {defineProps} from "vue";
import {ref} from "vue";
import {burgerCloseIcon} from "@/components/UI/svgs";
import {handleResize} from "../../../composables/resizeHandler";
import {useMenuStore} from "@/store/pinia/menu";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let store = useMenuStore()
const {windowWidth, windowHeight} = handleResize();

const isVideoOpen = ref(false);
const videoCloseTO = ref(null);
const activeVideo = ref(null);
const socialMedia = computed(() => store.indx?.widgets?.social_share)
const getFullUrl = computed(() => {
    return window.location.href;
});
const handleVideoClose = () => {
    document.querySelector('.project-overview-main__video-modal').classList.add('is-closing');
    videoCloseTO.value = window.setTimeout(() => {
        isVideoOpen.value = false;
    }, 1300);
}

const openVideo = (url) => {
    isVideoOpen.value = true
    activeVideo.value = url
}
let mediaTags = computed(() => store.indx?.terms?.media_tags)

let item = computed(() => {
    let item = props.contentData?.data?.list?.[0]
    return {
        ...item,
        date: store.getLang === 'ge' ? moment(item.date).locale("ka").format('DD MMM. YYYY')
            : store.getLang === 'ru' ? moment(item.date).locale("ru").format('DD MMM. YYYY') : moment(item.date).format('DD MMM. YYYY'),
        desktop1: item.image1?.[0]?.devices?.desktop,
        mobile1: item.image1?.[0]?.devices?.mobile,
        tablet1: item.image1?.[0]?.devices?.tablet,
        desktop2: item.image2?.[0]?.devices?.desktop,
        mobile2: item.image2?.[0]?.devices?.mobile,
        tablet2: item.image2?.[0]?.devices?.tablet,
        video1url: item.video1?.videoFile?.url,
        video2url: item.video2?.videoFile?.url,
        tags: mediaTags.value.filter(tag => {
            return item.media_tags?.includes(tag.id)
        })
    }
})
let router = useRouter()

const changeUrl = () => {
    router.push({
        path: '/media',
    })
}

</script>

<style lang="scss" scoped>
.media-single {
    color: $primaryNavy;
    padding: 55px 354px 144px;
    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
        padding: 55px 240px 144px;
    }
    @media only screen and (max-width: 1023px) {
        padding: 55px 100px 89px;
    }
    @media only screen and (max-width: 767px) {
        padding: 55px 20px 89px;
    }

    .date {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        transition: all .45s ease-out;
    }

    .header-title {
        margin-top: 8px;
        font-weight: 500;
        font-size: 34px;
        text-transform: uppercase;
        transition: all .45s ease-out;
        @media only screen and (max-width: 1365px) {
            font-size: 21px;
        }
    }

    .tag-container {
        margin-top: 34px;
        display: flex;
        align-items: center;

        .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: 0 5px;
            height: 24px;
            background: rgba(207, 203, 191, 0.3);
            border-radius: 3px;
            margin-right: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;

            .title {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }

    .image {
        width: 100%;
        height: 610px;
        object-fit: cover;
        @media only screen and (max-width: 1023px) {
            height: 450px;
        }
        @media only screen and (max-width: 767px) {
            height: 350px;
        }
    }

    .image-inner {
        position: relative;
        //cursor: pointer;
        overflow: hidden;
        margin-top: 55px;

        &:hover {
            .image {
                //transform: scale(1.1);
            }
        }

        .image {
            transition: all .45s cubic-bezier(.23, -0.01, .03, 1);
        }

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            transition: all .45s cubic-bezier(.23, -0.01, .03, 1);
        }

    }

    .content :deep {
        margin-top: 72px;

        h2, h1, h3 {
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 500;
            font-size: 34px;
        }

        span, p {
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
        }
    }

    .media-footer {
        margin-top: 55px;

        .title {
            font-weight: 500;
            font-size: 34px;
            line-height: 41px;
        }

        .icon {
            transition: all .45s cubic-bezier(.23, -0.01, .03, 1);

            &:hover {
                opacity: .7;
            }
        }

        .down {
            margin-top: 55px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 34px;
            border-top: 2px solid rgba(191, 191, 191, 0.2);

            .social-item {
                cursor: pointer;
            }

            .box {
                display: flex;
                align-items: center;

                svg {
                    transform: rotate(180deg) translateX(0);
                }

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.1em;
                }

                .icon {
                    margin-left: 9px;
                }

                &.right {
                    cursor: pointer;

                    &:hover {
                        svg {
                            animation: goBack .45s ease-out;
                        }

                        .title {
                            opacity: .6;
                        }
                    }

                    svg {
                        transition: all .45s ease-out;
                    }

                    .title {
                        margin-left: 13px;
                        transition: all .45s ease-out;
                    }
                }
            }

        }
    }

    @keyframes goBack {
        0% {
            transform: rotate(180deg) translateX(0);
        }
        50% {
            transform: rotate(180deg) translateX(10px);
        }
        100% {
            transform: rotate(180deg) translateX(0);
        }
    }
}

</style>
<style lang="scss">
.project-overview-main__video-modal.media {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $blackBlue;
    height: 100%;
    width: 100%;
    z-index: 100;

    animation-name: clipOverlay;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    animation-duration: 500ms;
    animation-fill-mode: forwards;

    .lh-0 {
        right: 50px;
        top: 50px;
        width: 60px;
        height: 60px;
        z-index: 4;
        border-radius: 50%;
        border: 2px solid $midSilver;

        svg {
            width: 16px;
            height: 16px;

            path {
                stroke: $white;
            }
        }

    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        object-fit: contain;
        animation-name: activeMenuImageOpacity;
        animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        animation-duration: 700ms;
        animation-fill-mode: forwards;
        animation-delay: .5s;


    }

    &.is-closing {
        video {
            animation-name: opacityReverse;
            animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
            animation-duration: 500ms;
            animation-fill-mode: forwards;
            animation-delay: 0s;


        }

        animation-name: clipOverlayReverse;
        animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
        animation-duration: 500ms;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;


    }
}

body {
    &.is-dark {
        .media-single {
            color: white;
        }
    }
}

</style>
